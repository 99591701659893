// @mui
import { Box, Typography } from '@mui/material';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

// assets
import { useConfig } from 'providers/Config';
import { useLocale } from 'providers/Locale';

// ------------------------------------------------------------------------------

const LocaleList = ({ handleClose = () => {} }) => {
  const { appConfig } = useConfig();
  const { locales } = appConfig.locale || [];
  const { setLocale } = useLocale();

  const handleLocale = (newLocale) => {
    setLocale(newLocale);
    handleClose();
  };

  return (
    <MenuList>
      {locales?.map((locale) => (
        <MenuItem
          key={'locales_' + locale.locale}
          onClick={() => handleLocale(locale.locale)}
        >
          <Box
            component="img"
            alt={locale.label}
            src={locale.icon}
            sx={{ width: 28, mr: 2 }}
          />
          <Typography variant="body1">{locale.label}</Typography>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default LocaleList;
