import { useState } from 'react';
// import { Link } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Popover } from '@mui/material';

// third-party

// project
import LocaleList from './LocaleList';

// assets
import { IconLanguage } from '@tabler/icons';

// --------------------------------------------------------------------------------------

const LocaleSection = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(null);
  const handleOpen = (e) => {
    setOpen(e.currentTarget);
  };

  const handleClose = (event) => {
    setOpen(null);
  };

  return (
    <>
      <Box
        sx={{
          ml: 1,
          mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 0.5
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: `${theme.shape.borderRadius}px` }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              bgcolor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                bgcolor: theme.palette.primary.dark,
                color: theme.palette.primary.light
              }
            }}
            // ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleOpen}
            color="inherit"
          >
            <IconLanguage stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 5.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: `${theme.shape.borderRadius}px`
            }
          }
        }}
      >
        {/* <Stack spacing={0.75}> */}
        <LocaleList handleClose={handleClose} />

        {/* </Stack> */}
      </Popover>
    </>
  );
};

export default LocaleSection;
