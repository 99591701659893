import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider
} from '@mui/material';

// -------------------------------------------------------------------
const CardActionsWrapper = styled(CardActions)(
  ({ theme }) => `
       background: ${theme.palette.grey[200]};
       padding: ${theme.spacing(2)};
  `
);

const MainCard = forwardRef(
  (
    {
      avatar,
      title,
      subheader,
      children,
      headeraction,
      cardactions,
      sx = {},
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        sx={{
          border: '1px solid', // border ? '1px solid' : 'none',
          borderColor: theme.palette.grey[100],
          ':hover': {
            boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'
          },
          ...sx
        }}
        {...others}
      >
        <CardHeader
          avatar={(avatar && <Avatar src={avatar} />) || undefined}
          titleTypographyProps={{ variant: 'h4' }}
          subheaderTypographyProps={{ variant: 'subtitle2' }}
          title={title}
          subheader={subheader}
          action={headeraction}
        />
        {title && <Divider />}
        <CardContent>{children}</CardContent>
        {cardactions && (
          <CardActionsWrapper
            sx={{
              display: { xs: 'block', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {cardactions}
          </CardActionsWrapper>
        )}
      </Card>
    );
  }
);

MainCard.propTypes = {
  avatar: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]),
  subheader: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]),
  children: PropTypes.node,
  headeraction: PropTypes.node,
  cardactions: PropTypes.node,
  sx: PropTypes.object
};

export default MainCard;
