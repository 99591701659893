// @mui
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ----------------------------------------------------------------------------------

const Logo = () => {
  const theme = useTheme();

  const colorSx = {
    color: theme.palette.text.primary
  };
  return (
    <Typography variant="h3" sx={{ ...colorSx }}>
      Mars Cloud
    </Typography>
  );
};

export default Logo;
