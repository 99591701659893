import { useTheme } from '@emotion/react';
import { Box, Link, Typography } from '@mui/material';
import { useConfig } from 'providers/Config';
import { useMenu } from 'providers/Menu';

const Footer = (props) => {
  const { appConfig } = useConfig();
  const theme = useTheme();
  const { isMenuOpen } = useMenu();
  return (
    <Box
      sx={{
        boxSizing: 'inherit',
        height: `${appConfig.sizing.footerHeight}px`,
        width: {
          xs: 1, sm: isMenuOpen
            ? `calc(100% - ${appConfig.sizing.siderMenuWidth}px)`
            : `calc(100% - ${appConfig.sizing.siderMenuMiniWidth}px)`
        },
        ml: {
          xs: 0, sm: isMenuOpen
            ? `${appConfig.sizing.siderMenuWidth}px`
            : `${appConfig.sizing.siderMenuMiniWidth}px`
        },

        position: 'fixed',
        bottom: 0,
        zIndex: theme.zIndex.drawer - 1,
        backgroundColor: theme.palette.background.default
      }}
    >
      <Typography
        variant="subtitle2"
        color="text.secondary"
        component="div"
        {...props}
        sx={{ p: 1, textAlign: 'center', verticalAlign: 'center' }}
      >
        {'Copyright © '}
        <Link color="inherit" href="https://themarscloud.com/">
          The Mars Cloud
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};

export default Footer;
