/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(fontFamily) {
  return {
    fontFamily,
    h1: {
      fontWeight: 400,
      fontSize: '2.375rem',
      lineHeight: 1.21
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.875rem',
      lineHeight: 1.27
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: 1.33
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.4
    },
    h5: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.57
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.6
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.6
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.6
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.6
    },
    button: {
      fontWeight: 500,
      // textTransform: null,
      textTransform: 'capitalize'
    },
    // mainContent: {
    //     // backgroundColor: theme.background,
    //     width: '100%',
    //     minHeight: 'calc(100vh - 88px)',
    //     flexGrow: 1,
    //     padding: '20px',
    //     marginTop: '88px',
    //     marginRight: '20px',
    //     // borderRadius: `${theme?.borderRadius}px`
    // },
    // menuCaption: {
    //     fontSize: '0.875rem',
    //     fontWeight: 500,
    //     padding: '6px',
    //     textTransform: 'capitalize',
    //     marginTop: '10px'
    // },
    // subMenuCaption: {
    //     fontSize: '0.6875rem',
    //     fontWeight: 500,
    //     color: "#ededed",
    //     textTransform: 'capitalize'
    // },
    commonAvatar: {
      cursor: 'pointer',
      borderRadius: 1
    },
    smallAvatar: {
      width: '22px',
      height: '22px',
      fontSize: '1rem'
    },
    mediumAvatar: {
      width: '34px',
      height: '34px',
      fontSize: '1.2rem'
    },
    largeAvatar: {
      width: '44px',
      height: '44px',
      fontSize: '1.5rem'
    }
  };
}
