import React, { lazy } from 'react';

// third-party
import { includes } from 'lodash';

// mocks
import { POSTS } from '__mocks__/blogs';

// project
import { ErrorBoundary } from 'components/errorboundary';
import MainLayout from 'layouts/MainLayout';
import Loader from 'components/Loader';

const Blog = Loader(lazy(() => import('pages/blog/Blog')));
const BlogDetail = Loader(lazy(() => import('pages/blog/BlogDetail')));
const BlogCreate = Loader(lazy(() => import('pages/blog/BlogCreate')));

export const blogRoutes = {
  path: '/pages',
  element: <MainLayout />,
  children: [
    {
      path: 'blogs',
      element: <Blog />
      ,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'blogs/:postId',
      element: <BlogDetail />,
      loader: async ({ params }) => {
        return POSTS.filter((post) => includes(post.id, params.postId))[0]; //fetch(`/api/teams/${params.teamId}.json`);
      },
      errorElement: <ErrorBoundary />
    },
    {
      path: 'blogs/create',
      element: <BlogCreate />,
      errorElement: <ErrorBoundary />
    }
  ]
};
