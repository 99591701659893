import { faker } from '@faker-js/faker';
import { sample, sampleSize } from 'lodash';

export const customers = [...Array(20)].map((_, index) => ({
  id: faker.datatype.uuid(),
  name: faker.name.fullName(),
  avatarUrl: require(`assets/images/users/user${index + 1}.png`),
  email: faker.internet.email(),
  phone: faker.phone.imei(),
  address: {
    country: faker.address.county(),
    state: faker.address.state(),
    city: faker.address.city(),
    street: faker.address.street()
  },
  payment: {
    creditCard: faker.finance.creditCardNumber('63[7-9]#-####-####-###L'),
    storeCredit: faker.finance.amount(5, 10, 2, '$'),
    paid: ` 2 (${faker.finance.amount(5, 10, 2, '$')})`,
    draft: `1 (${faker.finance.amount(5, 10, 2, '$')})`,
    stateRegion: ` 2 (${faker.finance.amount(5, 10, 2, '$')})`,
    unpaidDue: ` 1 (${faker.finance.amount(5, 10, 2, '$')})`,
    refunded: ' 0 ($0.00)',
    grossIncome: ''
  },
  company: faker.company.name(),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer'
  ]),
  tags: sampleSize(
    [
      'Leader',
      'Hr Manager',
      'UI Designer',
      'UX Designer',
      'UI/UX Designer',
      'Project Manager',
      'Backend Developer',
      'Full Stack Designer',
      'Front End Developer',
      'Full Stack Developer'
    ],
    3
  ),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  createdAt: faker.date.past(),
  emails: [
    {
      id: faker.datatype.uuid(),
      type: sample(['Order confirmation', 'Order canceled']),
      content: faker.random.words(80),
      createdAt: faker.date.past()
    },
    {
      id: faker.datatype.uuid(),
      type: sample(['Order confirmation', 'Order canceled']),
      content: faker.random.words(80),
      createdAt: faker.date.past()
    },
    {
      id: faker.datatype.uuid(),
      type: sample(['Order confirmation', 'Order canceled']),
      content: faker.random.words(80),
      createdAt: faker.date.past()
    }
  ],
  invoices: [
    {
      id: faker.datatype.uuid(),
      total: faker.commerce.price(500, 2000),
      status: sample(['paid', 'unpaid', 'canceled']),
      createdAt: faker.date.past()
    },
    {
      id: faker.datatype.uuid(),
      total: faker.commerce.price(500, 2000),
      status: sample(['paid', 'unpaid', 'canceled']),
      createdAt: faker.date.past()
    },
    {
      id: faker.datatype.uuid(),
      total: faker.commerce.price(500, 2000),
      status: sample(['paid', 'unpaid', 'canceled']),
      createdAt: faker.date.past()
    }
  ]
}));
