import React, { lazy } from 'react';

// third-party
import { includes } from 'lodash';

// mocks
import { customers } from '__mocks__/customers';

// project
import { ErrorBoundary } from 'components/errorboundary';
import MainLayout from 'layouts/MainLayout';
import Loader from 'components/Loader';
const Customer = Loader(lazy(() => import('pages/customers')));
const Products = Loader(lazy(() => import('pages/products')));
const Account = Loader(lazy(() => import('pages/account')));
const Mail = Loader(lazy(() => import('pages/mail')));
const Chat = Loader(lazy(() => import('pages/chat')));
const ProductList = Loader(lazy(() => import('pages/products/ProductList')));
const ProductEdit = Loader(lazy(() => import('pages/products/ProductEdit')));
const ProductDetails = Loader(
  lazy(() => import('pages/products/ProductDetails'))
);
const MailCompose = Loader(lazy(() => import('pages/mail/MailCompose')));
const MailRead = Loader(lazy(() => import('pages/mail/MailRead')));
const Contact = Loader(lazy(() => import('pages/contact')));
const Kanban = Loader(lazy(() => import('pages/kanban')));
const CustomerAdd = Loader(lazy(() => import('pages/customers/CustomerAdd')));
const CustomerDetail = Loader(
  lazy(() => import('pages/customers/CustomerDetail'))
);
const Order = Loader(lazy(() => import('pages/orders')));
const OrderDetail = Loader(lazy(() => import('pages/orders/OrderDetail')));

const UserPage = Loader(lazy(() => import('pages/user')));
const Error401 = Loader(lazy(() => import('pages/errors/401')));
const Error404 = Loader(lazy(() => import('pages/errors/404')));
const Error500 = Loader(lazy(() => import('pages/errors/500')));
const CustomerCardList = Loader(
  lazy(() => import('pages/customers/CustomerCardList'))
);

// sample page routing
const SamplePage = Loader(lazy(() => import('pages/sample-page')));

export const pageRoutes = {
  path: '/pages',
  element: <MainLayout />,
  children: [
    {
      path: 'chat',
      element: <Chat />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'customers',
      element: <Customer />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'customers/card',
      element: <CustomerCardList />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'customers/add',
      element: <CustomerAdd />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'customers/:cId',
      element: <CustomerDetail />,
      loader: async ({ params }) => {
        return customers.filter((customer) =>
          includes(customer.id, params.cId)
        )[0]; //fetch(`/api/teams/${params.teamId}.json`);
      },
      errorElement: <ErrorBoundary />
    },
    {
      path: 'orders',
      element: <Order />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'orders/add',
      element: <CustomerAdd />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'orders/detail',
      element: <OrderDetail />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'products',
      element: <Products />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'products/list',
      element: <ProductList />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'products/add',
      element: <ProductEdit />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'products/details',
      element: <ProductDetails />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'contact',
      element: <Contact />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'chat',
      element: <Chat />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'mail',
      element: <Mail />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'mail/compose',
      element: <MailCompose />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'mail/read',
      element: <MailRead />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'kanban',
      element: <Kanban />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'users',
      element: <UserPage />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'accounts',
      element: <Account />,
      errorElement: <ErrorBoundary />
    },

    {
      path: 'sample-page',
      element: <SamplePage />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'errors/401',
      element: <Error401 />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'errors/404',
      element: <Error404 />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'errors/500',
      element: <Error500 />,
      errorElement: <ErrorBoundary />
    }
  ]
};
