import React, { lazy } from 'react';

// project
import MainLayout from 'layouts/MainLayout';
import Loader from 'components/Loader';

import { ErrorBoundary } from 'components/errorboundary';

// utilities routing

const Accordions = Loader(lazy(() => import('pages/components/Accordions')));
const Avatars = Loader(lazy(() => import('pages/components/Avatars')));
const Badges = Loader(lazy(() => import('pages/components/Badges')));
// const BaseCard = Loader(lazy(() => import('pages/components/BaseCard')));
const Buttons = Loader(lazy(() => import('pages/components/Buttons')));
const Cards = Loader(lazy(() => import('pages/components/cards')));
const Customize = Loader(lazy(() => import('pages/components/Customize')));
const Forms = Loader(lazy(() => import('pages/components/Forms')));
// const Inputs = Loader(lazy(() => import('pages/components/Inputs')));
const Lists = Loader(lazy(() => import('pages/components/Lists')));
const Modals = Loader(lazy(() => import('pages/components/Modals')));
const Tabs = Loader(lazy(() => import('pages/components/Tabs')));
const Tooltips = Loader(lazy(() => import('pages/components/Tooltips')));

const PageHeader = Loader(lazy(() => import('pages/components/PageHeader')));
const Tables = Loader(lazy(() => import('pages/components/Tables')));
const Charts = Loader(lazy(() => import('pages/components/Chart')));
const UtilsMaterialIcons = Loader(
  lazy(() => import('pages/components/MaterialIcons'))
);
const UtilsTablerIcons = Loader(
  lazy(() => import('pages/components/TablerIcons'))
);
const Widgets = Loader(lazy(() => import('pages/components/cards/Widgets')));
const BlogCard = Loader(lazy(() => import('pages/components/cards/BlogCard')));

export const componentRoutes = {
  path: '/components',
  element: <MainLayout />,
  children: [
    {
      path: 'accordions',
      element: <Accordions />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'avatars',
      element: <Avatars />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'buttons',
      element: <Buttons />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'badges',
      element: <Badges />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'cards',
      element: <Cards />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'forms',
      element: <Forms />,
      errorElement: <ErrorBoundary />
    },
    // {
    //     path: 'inputs',
    //     element: <Inputs />,
    //     errorElement: <ErrorBoundary />,
    // },
    {
      path: 'lists',
      element: <Lists />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'modals',
      element: <Modals />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'tables',
      element: <Tables />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'tabs',
      element: <Tabs />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'tooltips',
      element: <Tooltips />,
      errorElement: <ErrorBoundary />
    },

    {
      path: 'pageheading',
      element: <PageHeader />,
      errorElement: <ErrorBoundary />
    },
    // {
    //     path: ' chip',
    //     element: <UtilsTypography />
    // },
    // {
    //     path: 'cards/base',
    //     element: <BaseCard />
    // },
    {
      path: 'cards/blog',
      element: <BlogCard />,
      errorElement: <ErrorBoundary />
    },
    // {
    //     path: 'cards/basic',
    //     element: <UtilsTypography />
    // },
    // {
    //     path: 'cards/advanced',
    //     element: <UtilsTypography />
    // },
    {
      path: 'cards/widgets',
      element: <Widgets />,
      errorElement: <ErrorBoundary />
    },
    // {
    //     path: 'cards/statistics',
    //     element: <UtilsTypography />
    // },
    {
      path: 'charts',
      element: <Charts />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'icons/tabler-icons',
      element: <UtilsTablerIcons />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'icons/material-icons',
      element: <UtilsMaterialIcons />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'customize',
      element: <Customize />,
      errorElement: <ErrorBoundary />
    }
  ]
};
