import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

export default function Introduction() {
  return (
    <Grid container gridRow={3}>
      <Grid item xs={8} sm={8} md={8} lg={8}>
        <Typography variant="h4"> Create-React-App</Typography>

        <Typography variant="h4">Key features</Typography>
        <Stack spacing={1}>
          {[
            'uilt with MUI version 5.8.2',
            'React Scripts (create-react-app tools)',
            'JavaScript versions included in all pricing plans',
            'Multiple layout templates',
            '17 niche dashboard pages',
            '6 applications demos',
            '7 layout blueprints',
            '7 management sections',
            '6 dark/light colours schemes',
            '500+ components',
            'Right-To-Left Support',
            'Fully Translation Ready Texts',
            'Formik & YUP form validations',
            'React Hooks, Context, Redux Toolkit',
            'Backend ready, mocked API calls',
            'Multiple Auth methods included (JWT Web Tokens, Firebase, Amplify, Auth0)',
            '96+ Google Lighthouse performance, best practices, and SEO scores across all app pages',
            'Perfectly responsive. Browse the live preview and resize any page',
            'PWA Optimisedå'
          ].map((item, index) => (
            <Typography key={index} variant="body1">
              -{item}
            </Typography>
          ))}
        </Stack>

        <Typography variant="h4">Design files</Typography>
        <Typography variant="body1">
          All pages from the live preview demo we've set up have design source
          files available.
        </Typography>
        <Typography variant="h4">Support tickets</Typography>
        <Typography variant="body1">
          If you need help you can open a support ticket by sending an email to
          xinzhong.yu@outlook.com.
        </Typography>
        <Typography variant="h4">Try the free version</Typography>
        <Typography variant="body1">
          We recently released as open-source a free version of Tokyo React
          Admin Dashboard with a minimal set of components. You can view a live
          demo here.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h4"> Package type</Typography>
      </Grid>
    </Grid>
  );
}
