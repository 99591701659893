import { createBrowserRouter } from 'react-router-dom';

// project
import config from 'config';
// routes
import { blogRoutes } from './blogRoutes';
import { authRoutes } from './authRoutes';
import { themeRoutes } from './themeRoutes';
import { dashboardRoutes } from './dashboardRoutes';
import { pageRoutes } from './pageRoutes';
import { homeRoutes } from './homeRoutes';
import { componentRoutes } from './componentRoutes';
import { formRoutes } from './formRoutes';

const router = createBrowserRouter(
  [
    homeRoutes,
    dashboardRoutes,
    pageRoutes,
    themeRoutes,
    authRoutes,
    blogRoutes,
    componentRoutes,
    formRoutes
  ],
  {
    basename: config.basename
  }
);

export default router;
