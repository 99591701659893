import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';

// project
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/transitions';
import User2 from 'assets/images/users/user.jpeg';

// assets
import { IconLogout, IconSettings, IconUser } from '@tabler/icons';
import { useConfig } from 'providers/Config';

// ------------------------------------------------------------------------

const ProfileSection = () => {
  const theme = useTheme();
  // const customization = useSelector((state) => state.customization);
  const { appConfig } = useConfig();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    console.log('Logout');
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box
        sx={{
          ml: 1,
          mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 0.5
          }
        }}
      >
        <Avatar
          src={User2}
          sx={{
            ...theme.typography.mediumAvatar,
            width: 42,
            height: 42,
            margin: '8px 0 8px 8px !important',
            cursor: 'pointer'
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={handleToggle}
        />
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          bgcolor: theme.palette.grey[100]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Morning,</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                        >
                          John Yu
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">
                        themarscloud@gmail.com
                      </Typography>
                    </Stack>
                  </Box>
                  {/* <PerfectScrollbar style={{ height: 180, maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                  <Box sx={{ p: 2 }}>
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: `${theme.shape.borderRadius}px`,
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          // mt: 0.5
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{
                          borderRadius: `${appConfig.theme.borderRadius}px`
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) =>
                          handleListItemClick(event, 1, '/pages/accounts')
                        }
                      >
                        <ListItemIcon>
                          <IconUser stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Grid
                              container
                              spacing={1}
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Typography variant="body1">Profile</Typography>
                              </Grid>
                              {/* <Grid item>
                                                                    <Chip
                                                                        label="04"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    />
                                                                </Grid> */}
                            </Grid>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${appConfig.theme.borderRadius}px`
                        }}
                        selected={selectedIndex === 0}
                        onClick={(event) =>
                          handleListItemClick(event, 0, '/pages/settings')
                        }
                      >
                        <ListItemIcon>
                          <IconSettings stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1">Settings</Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${appConfig.theme.borderRadius}px`
                        }}
                        selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1">Logout</Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
