import React, { lazy } from 'react';

// project
import { ErrorBoundary } from 'components/errorboundary';
import MainLayout from 'layouts/MainLayout';
import Loader from 'components/Loader';
import Introduction from 'pages/introduction';

const Analytics = Loader(lazy(() => import('pages/dashboard/Analytics')));
const ECommerce = Loader(lazy(() => import('pages/dashboard/ECommerce')));
const Overview = Loader(lazy(() => import('pages/dashboard/Overview')));
const CryptoDashboard = Loader(lazy(() => import('pages/dashboard/Ctypto')));

export const dashboardRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Overview />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'dashboard/Overview',
      // element: <DashboardDefault />
      element: <Overview />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'dashboard/analytics',
      element: <Analytics />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'dashboard/crypto',
      element: <CryptoDashboard />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'dashboard/ecommerce',
      element: <ECommerce />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'dashboard/introduction',
      element: <Introduction />,
      errorElement: <ErrorBoundary />
    }
  ]
};
