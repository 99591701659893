import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

const POST_TITLES = [
  'Recording Questions Using Your Writer',
  'Asked and answered: the results for the 2022 Developer survey are here!',
  'Now Licensed Under Creative Commons',
  'Easy Background Tasks in ASP.NET',
  'I Don’t Give a Damn About My Reputation',
  'Solving the “Fastest Gun in the West” problem',
  'I Move to Close This Question',
  'UserVoice Supports OpenID',
  'Server Speed Tests',
  'Reputation Bounty for Unanswered Questions',
  'Now Showing: Email Notifications',
  'New Datacenter Migration'
];

export const STATUS = ['Published', 'Draft'];

export const CATEGORY = ['Business', 'Science', 'Health', 'Sport', 'Arts'];

export const POSTS = [...Array(8)].map((_, index) => ({
  id: faker.datatype.uuid(),
  title: POST_TITLES[index + 1],
  category: sample(CATEGORY),
  content: faker.lorem.paragraph(),
  author: {
    name: faker.internet.userName(),
    avatarUrl: require(`assets/images/users/user${index + 1}.png`)
  },
  cover: require(`assets/images/pictures/pic${index + 1}.jpg`), //images[index].img,
  view: faker.datatype.number(),
  comment: faker.datatype.number(),
  share: faker.datatype.number(),
  favorite: faker.datatype.number(),
  status: sample(STATUS),
  createdAt: faker.date.past()
}));
