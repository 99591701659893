import React from 'react'
import { useRouteError } from 'react-router-dom';

//--------------------------------------------------------
const ErrorBoundary = () => {
    let error = useRouteError();
    return (
        <>
            <h2>Error 💥</h2>
            <p>{error.message}</p>
        </>
    );
}

export default ErrorBoundary;