import React, { lazy } from 'react';

// project
import { ErrorBoundary } from 'components/errorboundary';
import Loader from 'components/Loader';
// import Loader  from 'components/Loader';
const Home = Loader(lazy(() => import('pages/home')));
const HomeLayout = Loader(lazy(() => import('layouts/HomeLayout')));

export const homeRoutes = {
  path: '/home',
  element: <HomeLayout />,
  children: [
    {
      path: '',
      element: <Home />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'documents',
      element: <Home />,
      errorElement: <ErrorBoundary />
    }
  ]
};
