import React, { lazy } from 'react';
// project

import MinimalLayout from 'layouts/MinimalLayout';
import { ErrorBoundary } from 'components/errorboundary';
import Loader from 'components/Loader';
const AuthLogin3 = Loader(
  lazy(() => import('pages/auth/authentication/authentication3/Login3'))
);
const AuthRegister3 = Loader(
  lazy(() => import('pages/auth/authentication/authentication3/Register3'))
);

export const authRoutes = {
  path: '/auth',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin3 />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'register',
      element: <AuthRegister3 />,
      errorElement: <ErrorBoundary />
    }
  ]
};
