// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';

// assets
import User1 from 'assets/images/users/user.jpeg';
import { Mail } from '@mui/icons-material';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// --------------------------------------------------------------------

const NotificationList = () => {
  const theme = useTheme();

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        // borderRadius: `${theme.shape.borderRadius}px`,
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      <ListItemWrapper>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt="John Yu" src={User1} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle1">
                {' '}
                I have released a new design file.
              </Typography>
            }
          />
        </ListItem>
        <Grid container direction="column" className="list-container">
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography variant="subtitle2">
              I have released a new design.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              1 min ago
            </Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>
      <Divider />

      <ListItemWrapper>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar
              sx={{
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                border: 'none',
                borderColor: theme.palette.primary.main
              }}
            >
              <Mail stroke={1.5} size="1.3rem" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle1">
                Hi, John! we found an vital issue in new release.
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Grid container justifyContent="flex-end">
              <Grid item></Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction="column" className="list-container">
          <Grid item xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              3 min ago
            </Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>
      <Divider />
      <ListItemWrapper>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt="John Yu" src={User1} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="subtitle1">
                Uploaded two file on S3
              </Typography>
            }
          />
        </ListItem>
        <Grid container direction="column" className="list-container">
          <Grid item xs={12}>
            <Typography variant="caption" display="block" gutterBottom>
              5 min ago
            </Typography>
          </Grid>
        </Grid>
      </ListItemWrapper>
    </List>
  );
};

export default NotificationList;
