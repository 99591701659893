import React, { lazy } from 'react';

// project
import { ErrorBoundary } from 'components/errorboundary';
import MainLayout from 'layouts/MainLayout';
import Loader from 'components/Loader';
// import { ErrorBoundary } from 'components/errorboundary';

// utilities routing

const UtilsTypography = Loader(
  lazy(() => import('pages/themes/Typography'))
);
const UtilsColor = Loader(lazy(() => import('pages/themes/Color')));
const UtilsShadow = Loader(lazy(() => import('pages/themes/Shadow')));

export const themeRoutes = {
  path: '/utils',
  element: <MainLayout />,
  children: [
    {
      path: 'typography',
      element: <UtilsTypography />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'color',
      element: <UtilsColor />,
      errorElement: <ErrorBoundary />
    },
    {
      path: 'shadow',
      element: <UtilsShadow />,
      errorElement: <ErrorBoundary />
    }
  ]
};
