import React, { useEffect, useReducer } from 'react';
import Context from './Context';
import * as types from '../store/types';

function reducer(state, action) {
  const { type, auth } = action;
  switch (type) {
    case types.SET_AUTH:
      console.log('payload>>>',auth)
      return auth;
    case types.UPDATE_AUTH:
      return { ...state, ...auth };
    default:
      throw new Error();
  }
}

const Provider = ({ children, persistKey = 'auth' }) => {
  const persistAuth =  localStorage.getItem(persistKey);
  const [auth, dispatch] = useReducer(reducer, persistAuth || {});

  const setAuth = (auth) => {
    dispatch({ type: types.SET_AUTH, auth });
  };

  const updateAuth = (auth) => {
    dispatch({ type: types.UPDATE_AUTH, auth });
  };

  useEffect(() => {
    try {
      localStorage.setItem(persistKey, JSON.stringify(auth));
    } catch (error) {
      console.warn(error);
    }
  }, [auth, persistKey]);

  return (
    <Context.Provider value={{ auth, setAuth, updateAuth }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
