import React from 'react';

// @mui
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import MenuItem from './MenuItem';
import MenuCollapse from './MenuCollapse';

// third-party
import { includes } from 'lodash';

//project
import { useMenu } from 'providers/Menu';

const MenuGroup = (props) => {
  const { item, children } = props;
  const { isMenuOpen } = useMenu();
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        isMenuOpen && (
          <ListSubheader component="div" id="nested-list-subheader">
            {item.title}
          </ListSubheader>
        )
      }
    >
      {item.children &&
        item.children.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {includes(item.type, 'item') && (
                <MenuItem item={item} isMenuOpen={isMenuOpen} />
              )}
              {includes(item.type, 'collapse') && (
                <MenuCollapse item={item} isMenuOpen={isMenuOpen} />
              )}
            </React.Fragment>
          );
        })}
      {children}
    </List>
  );
};
export default MenuGroup;
