// @mui
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// styles
const SuspenseLoaderWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1301,
  width: '100%'
});

// ----------------------------------------------------------------------------------
const SuspenseLoader = () => (
  <SuspenseLoaderWrapper>
    <LinearProgress color="primary" />
  </SuspenseLoaderWrapper>
);

export default SuspenseLoader;
