// assets
import {
  IconDashboard,
  IconBuilding,
  IconBrandProducthunt,
  IconBrandGravatar,
  IconMail,
  IconApps,
  IconUsers,
  IconKey,
  IconTypography,
  IconPalette,
  IconShadow,
  IconCurrencyRipple,
  IconSquareCheck,
  IconFileAlert,
  IconTable,
  IconChartInfographic,
  IconChartAreaLine,
  IconBoxMultiple,
  IconSettingsAutomation
} from '@tabler/icons';

import { FormattedMessage } from 'react-intl';

// --------------------------------------------------------------------------------

const menuItems = {
  items: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
      type: 'group',
      children: [
        {
          id: 'overview',
          title: <FormattedMessage id="overview" defaultMessage="Overview" />,
          type: 'item',
          url: '/dashboard/overview',
          icon: IconDashboard,
          breadcrumbs: true
        },
        // {
        //     id: 'default',
        //     title: <FormattedMessage
        //         id="default"
        //         defaultMessage="Default"
        //     />,
        //     type: 'item',
        //     url: 'dashboard/default',
        //     icon:  IconDashboard,
        //     breadcrumbs: false
        // },
        {
          id: 'crypto',
          title: <FormattedMessage id="crypto" defaultMessage="Crypto" />,
          type: 'item',
          url: '/dashboard/crypto',
          icon: IconCurrencyRipple,
          breadcrumbs: false
        },
        {
          id: 'analytics',
          title: <FormattedMessage id="analytics" defaultMessage="Analytics" />,
          type: 'item',
          url: '/dashboard/analytics',
          icon: IconChartAreaLine,
          breadcrumbs: false
        }
        // {
        //     id: 'ecommerce',
        //     title: <FormattedMessage
        //         id="ecommerce"
        //         defaultMessage="ECommerce"
        //     />,
        //     type: 'item',
        //     url: '/dashboard/ecommerce',
        //     icon:  IconChartLine,
        //     breadcrumbs: false
        // }
      ]
    },
    {
      id: 'pages',
      title: <FormattedMessage id="pages" defaultMessage="Pages" />,
      // caption: 'Pages Caption',
      type: 'group',
      children: [
        {
          id: 'mail',
          title: <FormattedMessage id="mail" defaultMessage="Mail" />,
          type: 'item',
          url: '/pages/mail',
          icon: IconMail,
          breadcrumbs: true
        },
        {
          id: 'blog',
          title: <FormattedMessage id="blog" defaultMessage="Blog" />,
          type: 'item',
          url: '/pages/blogs',
          icon: IconBrandGravatar
        },
        // {
        //     id: 'chat',
        //     title: <FormattedMessage
        //         id="chat"
        //         defaultMessage="Chat"
        //     />,
        //     type: 'item',
        //     url: '/pages/chat',
        //     icon: IconMessage,
        //     breadcrumbs: true,
        // },
        {
          id: 'kanban',
          title: <FormattedMessage id="kanban" defaultMessage="Kanban" />,
          type: 'item',
          url: '/pages/kanban',
          icon: IconApps,
          breadcrumbs: true
        },
        {
          id: 'customers',
          title: <FormattedMessage id="customers" defaultMessage="Customers" />,
          type: 'collapse',

          icon: IconBuilding,
          // breadcrumbs: false
          children: [
            {
              id: 'customers-list',
              title: (
                <FormattedMessage id="customers-list" defaultMessage="List" />
              ),
              type: 'item',
              url: '/pages/customers'
            },
            {
              id: 'customers-add',
              title: (
                <FormattedMessage id="customers-add" defaultMessage="Edit" />
              ),
              type: 'item',
              url: '/pages/customers/add'
            }
          ]
        },
        {
          id: 'products',
          title: <FormattedMessage id="products" defaultMessage="Products" />,
          type: 'collapse',
          icon: IconBrandProducthunt,

          children: [
            // {
            //     id: 'Shop',
            //     title: <FormattedMessage
            //         id="shop"
            //         defaultMessage="Shop"
            //     />,
            //     type: 'item',
            //     url: '/pages/products',
            // },
            {
              id: 'product-list',
              title: (
                <FormattedMessage id="products-list" defaultMessage="List" />
              ),
              type: 'item',
              url: '/pages/products/list'
            },
            {
              id: 'product-add',
              title: (
                <FormattedMessage id="products-add" defaultMessage="Edit" />
              ),
              type: 'item',
              url: '/pages/products/add'
            },

            {
              id: 'product-detail',
              title: (
                <FormattedMessage
                  id="products-detail"
                  defaultMessage="Details"
                />
              ),
              type: 'item',
              url: '/pages/products/details'
            }
          ]
        },
        {
          id: 'orders',
          title: <FormattedMessage id="orders" defaultMessage="Orders" />,
          type: 'collapse',
          icon: IconSquareCheck,
          // breadcrumbs: false
          children: [
            {
              id: 'orders-list',

              title: (
                <FormattedMessage id="orders-list" defaultMessage="List" />
              ),

              type: 'item',

              url: '/pages/orders'
            },
            {
              id: 'orders-add',

              title: <FormattedMessage id="orders-add" defaultMessage="Edit" />,

              type: 'item',

              url: '/pages/orders/add'
            },

            {
              id: 'orders-detail',

              title: (
                <FormattedMessage id="orders-detail" defaultMessage="Detail" />
              ),

              type: 'item',

              url: '/pages/orders/detail'
            }
          ]
        },

        // {
        //     id: 'contact',
        //     title: <FormattedMessage
        //         id="contact"
        //         defaultMessage="Contact"
        //     />,
        //     type: 'item',
        //     url: '/pages/contact',
        //     icon: IconDeviceMobileMessage,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'user',
        //     title: <FormattedMessage
        //         id="user"
        //         defaultMessage="Users"
        //     />,
        //     type: 'item',
        //     url: '/pages/users',
        //     icon: IconUsers,
        //     breadcrumbs: false
        // },
        {
          id: 'account',
          title: <FormattedMessage id="account" defaultMessage="Account" />,
          type: 'item',
          icon: IconUsers,
          url: '/pages/accounts'
        },
        {
          id: 'authentication',
          title: (
            <FormattedMessage
              id="authentication"
              defaultMessage="Authentication"
            />
          ),
          type: 'collapse',
          icon: IconKey,
          children: [
            {
              id: 'login',
              title: <FormattedMessage id="login" defaultMessage="Login" />,
              type: 'item',
              url: '/auth/login',
              target: true
            },
            {
              id: 'register',
              title: 'Register',
              type: 'item',
              url: '/auth/register',
              target: true
            }
          ]
        },
        {
          id: 'error',
          title: <FormattedMessage id="error" defaultMessage="Error" />,
          type: 'collapse',
          icon: IconFileAlert,
          children: [
            {
              id: '401',
              title: <FormattedMessage id="401" defaultMessage="401" />,
              type: 'item',
              url: '/pages/errors/401',
              target: false
            },
            {
              id: '404',
              title: <FormattedMessage id="404" defaultMessage="404" />,
              type: 'item',
              url: '/pages/errors/404',
              target: false
            },
            {
              id: '500',
              title: <FormattedMessage id="500" defaultMessage="500" />,
              type: 'item',
              url: '/pages/errors/500',
              target: false
            }
          ]
        }
      ]
    },

    {
      id: 'themes',
      title: <FormattedMessage id="themes" defaultMessage="THEMES" />,
      type: 'group',
      children: [
        {
          id: 'util-typography',
          title: (
            <FormattedMessage id="typography" defaultMessage="Typography" />
          ),
          type: 'item',
          url: '/utils/typography',
          icon: IconTypography,
          breadcrumbs: false
        },
        {
          id: 'util-color',
          title: <FormattedMessage id="color" defaultMessage="Color" />,
          type: 'item',
          url: '/utils/color',
          icon: IconPalette,
          breadcrumbs: false
        },
        {
          id: 'util-shadow',
          title: <FormattedMessage id="shadow" defaultMessage="Shadow" />,
          type: 'item',
          url: '/utils/shadow',
          icon: IconShadow,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'customized',
      title: <FormattedMessage id="customized" defaultMessage="CUSTOMIZED" />,
      type: 'group',
      children: [
        {
          id: 'components',
          title: (
            <FormattedMessage id="components" defaultMessage="Components" />
          ),
          icon: IconSettingsAutomation,
          type: 'collapse',
          children: [
            // {
            //     id: 'util-customize',
            //     title: <FormattedMessage
            //         id="customize"
            //         defaultMessage="Customize"
            //     />,
            //     type: 'item',
            //     url: '/utils/customize',
            //     icon: IconCloudStorm,
            //     breadcrumbs: false
            // },
            // {
            //     id: 'accordions',
            //     title: <FormattedMessage
            //         id="accordions"
            //         defaultMessage="Accordions"
            //     />,
            //     type: 'item',
            //     url: '/components/accordions',
            //     // icon: IconBoxMultiple,
            // },
            {
              id: 'avatar',
              title: <FormattedMessage id="avatar" defaultMessage="Avatars" />,
              type: 'item',
              url: '/components/avatars'
              // icon: IconUserX,
            },

            // {
            //     id: 'badges',
            //     title: <FormattedMessage
            //         id="badges"
            //         defaultMessage="Badges"
            //     />,
            //     type: 'item',
            //     url: '/components/badges',
            //     // icon: IconBandage,
            // },
            {
              id: 'button',
              title: <FormattedMessage id="button" defaultMessage="Buttons" />,
              type: 'item',
              url: '/components/buttons'
              // icon: IconClick,
            },
            {
              id: 'cards',
              title: <FormattedMessage id="cards" defaultMessage="Cards" />,
              type: 'item',
              url: '/components/cards'
              // icon: IconPolaroid,
            },
            // {
            //     id: 'forms',
            //     title: <FormattedMessage
            //         id="forms"
            //         defaultMessage="Forms"
            //     />,
            //     type: 'item',
            //     url: '/components/forms',
            //     // icon: IconPerspective,
            //     breadcrumbs: false
            // },
            // {
            //     id: 'modals',
            //     title: <FormattedMessage
            //         id="modals"
            //         defaultMessage="Modals"
            //     />,
            //     type: 'item',
            //     url: '/components/modals',
            //     // icon: IconApps,
            //     breadcrumbs: false
            // },
            // {
            //     id: 'inputs',
            //     title: <FormattedMessage
            //         id="inputs"
            //         defaultMessage="Inputs"
            //     />,
            //     type: 'item',
            //     url: '/components/inputs',
            //     breadcrumbs: false
            // },
            {
              id: 'lists',
              title: <FormattedMessage id="lists" defaultMessage="Lists" />,
              type: 'item',
              url: '/components/lists',
              // icon: IconList,
              breadcrumbs: false
            },
            {
              id: 'tabs',
              title: <FormattedMessage id="tabs" defaultMessage="Tabs" />,
              type: 'item',
              url: '/components/tabs',
              // icon: IconDeviceDesktop,
              breadcrumbs: false
            },

            {
              id: 'pageheading',
              title: (
                <FormattedMessage
                  id="pageheading"
                  defaultMessage="Page Heading"
                />
              ),
              type: 'item',
              url: '/components/pageheading',
              // icon: IconHeading,
              breadcrumbs: false
            }
            //         {
            //             id: 'cardheading',
            //             title: <FormattedMessage
            //                 id="cardheading"
            //                 defaultMessage="Card Heading"
            //             />,
            //             type: 'item',
            //             url: '/components/cardheading',
            //             breadcrumbs: false
            //         },
            //         {
            //             id: 'card',
            //             title: <FormattedMessage
            //                 id="card"
            //                 defaultMessage="Cards"
            //             />,
            //             type: 'item',
            //             url: '/utils/cards/base',
            //         },
            // {
            //     id: 'chart',
            //     title: <FormattedMessage
            //         id="chart"
            //         defaultMessage="Charts"
            //     />,
            //     type: 'item',
            //     icon: IconChartInfographic,
            //     url: '/components/charts'
            // breadcrumbs: false
            // children: [
            //     {
            //         id: 'chart-basic',
            //         title: <FormattedMessage
            //             id="chart-basic"
            //             defaultMessage="Basic"
            //         />,
            //         type: 'item',
            //         url: '/utils/chart/basic'
            //     },
            // ]
            // },
            // {
            //     id: 'cards',
            //     title: <FormattedMessage
            //         id="cards"
            //         defaultMessage="Cards"
            //     />,
            //     type: 'collapse',

            //     icon: IconChartLine,
            //     // breadcrumbs: false
            //     children: [
            //         {
            //             id: 'blog',
            //             title: <FormattedMessage
            //                 id="blog"
            //                 defaultMessage="Blog Card"
            //             />,
            //             type: 'item',
            //             url: '/utils/cards/blog',
            //         },
            //         {
            //             id: 'basic',
            //             title: <FormattedMessage
            //                 id="basic"
            //                 defaultMessage="Basic"
            //             />,
            //             type: 'item',
            //             url: '/utils/cards/basic',
            //         },
            //         {
            //             id: 'advanced',
            //             title: <FormattedMessage
            //                 id="advanced"
            //                 defaultMessage="advanced"
            //             />,
            //             type: 'item',
            //             url: '/utils/cards/advanced',
            //         },
            //         {
            //             id: 'statistics',
            //             title: <FormattedMessage
            //                 id="statistics"
            //                 defaultMessage="Statistics"
            //             />,
            //             type: 'item',
            //             url: '/utils/cards/statistics',
            //         },
            //         {
            //             id: 'widgets',
            //             title: <FormattedMessage
            //                 id="widgets"
            //                 defaultMessage="Widgets"
            //             />,
            //             type: 'item',
            //             url: '/utils/cards/widgets',
            //             breadcrumbs: false
            //         }
            //     ]
            // }
          ]
        },
        {
          id: 'forms',
          title: <FormattedMessage id="forms" defaultMessage="Forms" />,
          type: 'item',
          url: '/forms/validation',
          icon: IconBoxMultiple
        },
        {
          id: 'chart',
          title: <FormattedMessage id="chart" defaultMessage="Charts" />,
          type: 'item',
          icon: IconChartInfographic,
          url: '/components/charts'
        },
        {
          id: 'tables',
          title: <FormattedMessage id="tables" defaultMessage="Tables" />,
          type: 'item',
          icon: IconTable,
          url: '/components/tables',
          breadcrumbs: false
        }
      ]
    }
    // {
    //     id: 'utilities',
    //     title: 'Utilities',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'util-customize',
    //             title: <FormattedMessage
    //                 id="customize"
    //                 defaultMessage="Customize"
    //             />,
    //             type: 'item',
    //             url: 'customize',
    //             icon: IconCloudStorm,
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'util-typography',
    //             title: <FormattedMessage
    //                 id="typography"
    //                 defaultMessage="Typography"
    //             />,
    //             type: 'item',
    //             url: '/utils/util-typography',
    //             icon: IconTypography,
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'util-color',
    //             title: <FormattedMessage
    //                 id="color"
    //                 defaultMessage="Color"
    //             />,
    //             type: 'item',
    //             url: 'utils/util-color',
    //             icon: IconPalette,
    //             breadcrumbs: false
    //         },

    //         {
    //             id: 'icons',
    //             title: <FormattedMessage
    //                 id="icons"
    //                 defaultMessage="Icons"
    //             />,
    //             type: 'collapse',
    //             icon: IconWindmill,
    //             children: [
    //                 {
    //                     id: 'tabler-icons',
    //                     title: 'Tabler Icons',
    //                     type: 'item',
    //                     url: '/icons/tabler-icons',
    //                     breadcrumbs: false
    //                 },
    //                 {
    //                     id: 'material-icons',
    //                     title: 'Material Icons',
    //                     type: 'item',
    //                     url: '/icons/material-icons',
    //                     breadcrumbs: false
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 'sample-docs-roadmap',
    //     type: 'group',
    //     children: [
    //         // {
    //         //     id: 'sample-page',
    //         //     title: 'Sample Page',
    //         //     type: 'item',
    //         //     url: '/sample-page',
    //         //     icon:  IconBrandChrome,
    //         // },
    //         {
    //             id: 'documentation',
    //             title: 'Documentation',
    //             type: 'item',
    //             url: 'https://themarscloud.com',
    //             icon: IconHelp,
    //             external: true,
    //             target: true
    //         }
    //     ]
    // }
  ]
};

export default menuItems;
