// third-party
import { presetPalettes } from '@ant-design/colors';
// volcano,yellow,lime,blue,geekblue,purple,magenta,grey, //red,gold,cyan,green
const paletteColors = (mode, color) => {
  const colors = presetPalettes;
  const {
    volcano,
    yellow,
    lime,
    blue,
    geekblue,
    purple,
    magenta,
    red,
    gold,
    cyan,
    green
  } = colors;

  const grey = {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#f0f0f0',
    300: '#d9d9d9',
    400: '#bfbfbf',
    500: '#8c8c8c',
    600: '#595959',
    700: '#262626',
    800: '#141414',
    900: '#000000',
    A50: '#fafafb',
    A100: '#fafafa',
    A200: '#bfbfbf',
    A400: '#434343',
    A700: '#1f1f1f',
    A800: '#e6ebf1'
  };
  const lightTextPrimary = grey[100];
  const darkTextPrimary = grey[800];

  let palette = {};
  switch (mode) {
    case 'light':
      palette = {
        primary: {
          lighter: colors[color][1],
          light: colors[color][3],
          main: colors[color][5],
          dark: colors[color][7],
          darker: colors[color][9],
          contrastText: lightTextPrimary
        },
        secondary: {
          lighter: grey[100],
          light: grey[300],
          main: grey[500],
          dark: grey[700],
          darker: grey[900],
          contrastText: lightTextPrimary
        },
        error: {
          lighter: red[1],
          light: red[3],
          main: red[5],
          dark: red[7],
          darker: red[9],
          contrastText: lightTextPrimary
        },
        warning: {
          lighter: gold[1],
          light: gold[3],
          main: gold[5],
          dark: gold[7],
          darker: gold[9],
          contrastText: lightTextPrimary
        },
        info: {
          lighter: cyan[1],
          light: cyan[3],
          main: cyan[5],
          dark: cyan[7],
          darker: cyan[9],
          contrastText: lightTextPrimary
        },
        success: {
          lighter: green[1],
          light: green[3],
          main: green[5],
          dark: green[7],
          darker: green[9],
          contrastText: lightTextPrimary
        },
        divider: grey[300],
        background: {
          paper: '#fdfdfd',
          default: grey.A50
        },
        text: {
          primary: grey[700],
          secondary: grey[500],
          disabled: grey[400]
        },
        grey,
        volcano,
        yellow,
        lime,
        blue,
        geekblue,
        purple,
        magenta
      };
      break;
    default:
      palette = {
        primary: {
          lighter: colors[color][0],
          light: colors[color][1],
          main: colors[color][2],
          dark: colors[color][4],
          darker: colors[color][6],
          contrastText: darkTextPrimary
        },
        secondary: {
          lighter: grey[50],
          light: grey[100],
          main: grey[200],
          dark: grey[400],
          darker: grey[600],
          contrastText: darkTextPrimary
        },
        error: {
          lighter: red[0],
          light: red[1],
          main: red[2],
          dark: red[4],
          darker: red[6],
          contrastText: lightTextPrimary
        },
        warning: {
          lighter: gold[0],
          light: gold[1],
          main: gold[2],
          dark: gold[4],
          darker: gold[6],
          contrastText: darkTextPrimary
        },
        info: {
          lighter: cyan[0],
          light: cyan[1],
          main: cyan[2],
          dark: cyan[4],
          darker: cyan[6],
          contrastText: darkTextPrimary
        },
        success: {
          lighter: green[0],
          light: green[1],
          main: green[2],
          dark: green[4],
          darker: green[6],
          contrastText: darkTextPrimary
        },
        grey,
        divider: grey[500],
        background: {
          paper: grey[800],
          default: grey[800]
        },
        text: {
          primary: grey[200],
          secondary: grey[100],
          disabled: grey[50]
        },
        volcano,
        yellow,
        lime,
        blue,
        geekblue,
        purple,
        magenta
      };
      break;
  }
  return palette;
};

export default paletteColors;
