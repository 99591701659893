import parseLanguages from 'utils/locale';
import themes from './themes';
import locales from './locales';

const config = {
  basename: '/',
  defaultPath: '/dashboard/overview',
  theme: {
    themes,
    colors: ['blue', 'magenta', 'purple', 'volcano', 'geekblue', 'lime'], //'yellow',
    initialMode: 'dark',
    initialColor: 'blue',
    initialDirection: 'ltr',
    initialFontFamily: `'Roboto', sans-serif`,
    initialBorderRadius: 8,
  },
  menu: {
    initialAuthMenuOpen: false,
    initialMiniMode: false,
    initialMenuOpen: true,
    initialMobileMenuOpen: false,
    initialMiniSwitchVisibility: true,
    initialMenuLayout: 'vertical',
    offlineIndicatorHeight: 12,
    useWindowWatcher: false,
  },
  locale: {
    defaultLocale: parseLanguages(['en', 'de', 'ru'], 'en'),
    locales
    // : [
    //     {
    //         value: 'en',
    //         messages: '',
    //         label: 'English',
    //         icon: us,
    //     },
    //     {
    //         value: 'de',
    //         messages: '',
    //         label: 'German',
    //         icon: de,
    //     },
    //     {
    //         value: 'ru',
    //         messages: '',
    //         label: 'Russian',
    //         icon: ru
    //     }
    // ],
  },
  sizing: {
    siderMenuWidth: 268,
    siderMenuMiniWidth: 66,
    appbarHeight: 76,
    footerHeight: 36
  },
  // outlinedFilled: true,
  // boxContainer: 'false',
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days'
  },
  auth: {
    signInURL: '/auth/login'
  },
  firebase: {
    apiKey: 'apiKey',
    authDomain: 'authDomain',
    projectId: 'tmc-material-react',
    storageBucket: 'tmc-material-react.appspot.com',
    messagingSenderId: 'messagingSenderId',
    appId: 'appId',
    measurementId: 'measurementId'
  },
  auth0: {
    client_id: 'client_id',
    domain: 'demo-localhost.us.auth0.com'
  }
};
export default config;
