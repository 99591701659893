// @mui

export default function componentStyleOverrides(theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 400
        },
        colorDefault: {
          background: theme.palette.grey[200],
          color: theme.palette.grey[50]
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: theme.colors?.grey[50],
          borderRadius: `${theme?.spacing?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.grey[400]
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.grey[200]
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          }
        },
        input: {
          fontWeight: 500,
          background: theme.colors?.grey[50],
          padding: '15.5px 14px',
          borderRadius: `${theme?.spacing?.borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          }
        },
        inputAdornedStart: {
          paddingLeft: 4
        },
        notchedOutline: {
          borderRadius: `${theme?.spacing?.borderRadius}px`
        }
      }
    }

    // MuiTabs: {
    //     styleOverrides: {
    //         root: {
    //             height: 38,
    //             minHeight: 38,
    //             overflow: 'visible',
    //         },
    //         indicator: {
    //             height: 38,
    //             minHeight: 38,

    //             borderRadius: 6,
    //             border: '1px solid ' + theme.palette.primary.dark,
    //             boxShadow: '0px 2px 10px ' + theme.palette.primary.light,
    //             textAlign: 'left'

    //         },
    //         scrollableX: {
    //             overflow: 'visible !important'
    //         },
    //         vertical: {
    //             height: 300,
    //             width: 180,
    //         },
    //         flexContainerVertical: {
    //             alignItems: 'flex-start',

    //         }
    //     }
    // },
    // MuiTab: {
    //     styleOverrides: {
    //         root: {
    //             padding: 0,
    //             height: 38,
    //             minHeight: 38,
    //             borderRadius: 6,
    //             transition: 'color .2s',
    //             textTransform: 'capitalize',
    //             //
    //             '&.MuiButtonBase-root': {
    //                 minWidth: 'auto',
    //                 paddingLeft: 20,
    //                 paddingRight: 20,
    //                 marginRight: 4,
    //             },
    //             '&.Mui-selected, &.Mui-selected:hover': {
    //                 color: theme.palette.grey[200],
    //                 zIndex: 5
    //             },
    //             '&:hover': {
    //                 color: theme.palette.grey[500]
    //             }
    //         }
    //     }
    // },
  };
}
