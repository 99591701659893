import { useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';

// @mui
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material';

// from project
import routes from 'config/routes';
import GlobalStyles from 'config/themes/globalStyles';
import { useConfig } from 'providers/Config';
import { MenuProvider } from 'providers/Menu';
import { AppThemeProvider, useAppTheme } from 'providers/Theme';

const LayoutContent = ({ appConfig = {}, children }) => {
  const { themes } = appConfig.theme;
  const { mode, color, direction, fontFamily, borderRadius } = useAppTheme();
  const customizedTheme = useMemo(
    () => themes(mode, color, direction, fontFamily, borderRadius),
    // eslint-disable-next-line
    [mode, color, direction, fontFamily, borderRadius]
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customizedTheme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider >
  );
};
function App() {
  const { appConfig } = useConfig();
  // const router = useRoutes(routes)
  return (
    <AppThemeProvider appConfig={appConfig}>
      <MenuProvider appConfig={appConfig}>
        <LayoutContent appConfig={appConfig}>
          <RouterProvider router={routes} />
          {/* {router} */}
        </LayoutContent>
      </MenuProvider>
    </AppThemeProvider>
  );
}

export default App;
