import React, { lazy } from 'react';

// project
import { ErrorBoundary } from 'components/errorboundary';
import MainLayout from 'layouts/MainLayout';
import Loader from 'components/Loader';


// utilities routing

const FormValidation = Loader(lazy(() => import('pages/forms/FormValidation')));

export const formRoutes = {
  path: '/forms',
  element: <MainLayout />,
  children: [
    {
      path: 'validation',
      element: <FormValidation />,
      errorElement: <ErrorBoundary />
    }
  ]
};
