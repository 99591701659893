import * as React from 'react';
import PropTypes from 'prop-types';

// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Toolbar, useMediaQuery } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
// project
import LogoSection from '../Logo';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import Setting from './SettingSection';
// assets
import { IconLayoutSidebarRight, IconLayoutSidebar, IconMenu2 } from '@tabler/icons';
import LocaleSection from './LocaleSection';
import { useMenu } from 'providers/Menu';


// ----------------------------------------------------------------------------
const AppBar = styled(MuiAppBar)(
  ({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
  })
);


const Header = () => {
  const theme = useTheme();
  const { isMiniMode, isMobileMenuOpen, isMenuOpen, toggleMenu } = useMenu();
  // const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = (e) => {
    e.preventDefault();
    toggleMenu('isMenuOpen', !isMenuOpen); //open
    toggleMenu('isMiniMode', !isMiniMode); //mobileOpen
    toggleMenu('isMobileMenuOpen', !isMobileMenuOpen); //mobileOpen
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      // elevation={0}
      // appbarHeight={appConfig.sizing.appbarHeight}
      // siderMenuWidth={appConfig.sizing.siderMenuWidth}
      sx={{
        //   direction: direction,
        //   p: 1,
        //   transition: isMenuOpen ? theme.transitions.create('width') : 'none',
        bgcolor: theme.palette.background.default,
        ...theme.palette.appbar
      }}
    >
      <Toolbar>
        <Box
          sx={{
            width: 228,
            display: 'flex',
            [theme.breakpoints.down('md')]: {
              width: 'auto'
            }
          }}
        >
          <Box
            component="span"
            sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
          >
            <LogoSection />
          </Box>
          <ButtonBase
            sx={{
              borderRadius: `${theme.shape.borderRadius}px`,
              //overflow: 'hidden'
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.primary.light,
                color: theme.palette.primary.dark,
                '&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.primary.light
                }
              }}
              onClick={handleDrawerToggle}
              color="inherit"
            >
              {!matchDownSm && (isMenuOpen ? (
                <IconLayoutSidebar stroke={1.5} size="1.3rem" />
              ) : (
                <IconLayoutSidebarRight stroke={1.5} size="1.3rem" />
              ))}
              {matchDownSm && <IconMenu2 />}
            </Avatar>
          </ButtonBase>
        </Box>

        {/* header search */}
        <SearchSection />
        <Box sx={{ flexGrow: 1 }} />

        {/* notification & profile */}
        <NotificationSection />
        <LocaleSection />
        <Setting />
        <ProfileSection />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
