import us from 'assets/images/flags/us.svg';
import de from 'assets/images/flags/de.svg';
import ru from 'assets/images/flags/ru.svg';
const locales = [
  {
    locale: 'en',
    messages: import('./en'),
    label: 'English',
    icon: us
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/en`),
  },
  {
    locale: 'de',
    messages: import('./de'),
    label: 'Deutsch',
    icon: de
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/de`),
  },
  {
    locale: 'ru',
    messages: import('./ru'),
    label: 'Russland',
    icon: ru
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/ru`),
  }
];

export default locales;
