import { Outlet } from 'react-router-dom';

// project

// -------------------------------------------------------------------

const MinimalLayout = () => (
  <>
    <Outlet />
  </>
);

export default MinimalLayout;
