// project import
import paletteColors from './paletteColors';

const themePalette = (mode, color) => {
  const paletteColor = paletteColors(mode, color);

  return {
    mode,
    common: {
      black: paletteColor.grey[800],
      white: paletteColor.grey[50]
    },
    action: {
      disabled: paletteColor.grey[300]
    },
    header: {
      height: '88px',
      boxShadow:
        '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)'
    },
    pageheading: {
      height: '88px',
      boxShadow:
        '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)'
    },
    appbar: {
      boxShadow:
        '0px 2px 3px rgba(159, 162, 191, .18), 0px 1px 1px rgba(159, 162, 191, 0.32)'
    },
    sidemenu: {
      boxShadow:
        '1px 0px 3px rgba(159, 162, 191, .10), 1px 0 1px rgba(159, 162, 191, 0.12)'
    },
    ...paletteColor
  };
};

export default themePalette;
