import React, { useEffect, useReducer } from 'react';
import Context from './Context';
import { useMediaQuery } from '@mui/material';
import {
  setIsAuthMenuOpen,
  setIsMiniMode,
  setIsMenuOpen,
  setIsMobileMenuOpen,
  setIsMiniSwitchVisibility,
  setMenuOrientation
} from './store/actions';
import reducer from './store/reducer';

const Provider = ({ appConfig, children, persistKey = 'menu' }) => {
  const { menu } = appConfig || {};

  const {
    initialAuthMenuOpen,
    initialMiniMode,
    initialMenuOpen,
    initialMobileMenuOpen,
    initialMiniSwitchVisibility,
    initialMenuLayout,
    // isOpen
  } = menu;

  const savedState = JSON.parse(localStorage.getItem(persistKey));

  const [menuStore, dispatch] = useReducer(reducer, {
    isAuthMenuOpen: initialAuthMenuOpen,
    isMiniMode: initialMiniMode,
    isMenuOpen: initialMenuOpen,
    isMobileMenuOpen: initialMobileMenuOpen,
    isMiniSwitchVisibility: initialMiniSwitchVisibility,
    menuLayout: initialMenuLayout,
    ...savedState
  });

  const props = {
    //setter
    toggleMenu(stateKey, stateValue = null) {
      if (stateKey === 'isAuthMenuOpen') {
        dispatch(
          setIsAuthMenuOpen(
            stateValue !== null ? stateValue : !menuStore.isAuthMenuOpen
          )
        );
      } else if (stateKey === 'isMiniMode') {
        dispatch(
          setIsMiniMode(
            stateValue !== null ? stateValue : !menuStore.isMiniMode
          )
        );
      } else if (stateKey === 'isMenuOpen') {
        dispatch(
          setIsMenuOpen(
            stateValue !== null ? stateValue : !menuStore.isMenuOpen
          )
        );
      } else if (stateKey === 'isMobileMenuOpen') {
        dispatch(
          setIsMobileMenuOpen(
            stateValue !== null ? stateValue : !menuStore.isMobileMenuOpen
          )
        );
      } else if (stateKey === 'isMiniSwitchVisibility') {
        dispatch(
          setIsMiniSwitchVisibility(
            stateValue !== null ? stateValue : !menuStore.isMiniSwitchVisibility
          )
        );
      } else if (stateKey === 'menuLayout') {
        dispatch(
          setMenuOrientation(
            stateValue !== null ? stateValue : !menuStore.menu_orientation
          )
        );
      }
    },

    //getters
    isAuthMenuOpen: menuStore.isAuthMenuOpen,
    isMiniMode: menuStore.isMiniMode,
    isMenuOpen: menuStore.isMenuOpen,
    isMobileMenuOpen: menuStore.isMobileMenuOpen,
    isMiniSwitchVisibility: menuStore.isMiniSwitchVisibility,
    isOpen: menuStore.isOpen,
    menuLayyout: menuStore.menuLayout
  };
  const isExtraSmallScreen = useMediaQuery('(min-width:0px)');
  const isSmallScreen = useMediaQuery('(min-width:600px)');
  const isMiddleScreen = useMediaQuery('(min-width:900px)');
  const isLargeScreen = useMediaQuery('(min-width:1200px)');

  useEffect(() => {
    try {
      localStorage.setItem(persistKey, JSON.stringify(menuStore));
    } catch (error) {
      console.warn(error);
    }
  }, [menuStore, persistKey]);

  return (
    <Context.Provider
      value={{
        isExtraSmallScreen,
        isSmallScreen,
        isMiddleScreen,
        isLargeScreen,
        ...props
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
