import React from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// project
import { useMenu } from 'providers/Menu';
import { useMediaQuery, useTheme } from '@mui/material';

const MenuItem = (props) => {
  const { item } = props;
  const theme = useTheme();
  const { isMenuOpen } = useMenu();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const Icon = item?.icon;
  const itemIcon = item?.icon ? (
    <Icon style={{ width: 24, height: 24 }} />
  ) : undefined;

  return (
    <ListItemButton
      onClick={() => navigate(item.url)}
      sx={{
        minHeight: 48,
        justifyContent: matchDownSm ? 'initial' : isMenuOpen ? 'initial' : 'center',
        px: 3
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: matchDownSm ? 3 : isMenuOpen ? 3 : 'auto',
          justifyContent: 'center'
        }}
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={item.title}
        sx={{ opacity: matchDownSm ? 1 : isMenuOpen ? 1 : 0 }}
      />
    </ListItemButton>
  );
};

export default MenuItem;
