// react
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

//from project
import Context from './Context';
import { useConfig } from 'providers/Config';
import { getLocaleMessages } from 'utils/locale';

const Provider = ({ children, persistKey = 'locale' }) => {
  const { appConfig } = useConfig();
  const { defaultLocale, locales } = appConfig.locale || {};

  const savedLocale = localStorage.getItem(persistKey);

  const [locale, setLocale] = useState(savedLocale || defaultLocale);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    localStorage.setItem(persistKey, locale);

    const loadMessages = async () => {
      const messages = await getLocaleMessages(locale, locales);
      setMessages(messages);
    };
    loadMessages();
    // eslint-disable-next-line
  }, [locale]);

  return (
    <Context.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        defaultLocale={locale}
        messages={messages}
        // onError={onError}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Provider;
