
// menu
export const SET_IS_MENU_OPEN = 'SET_IS_MENU_OPEN';
export const SET_IS_MINI_MODE = 'SET_IS_MINI_MODE';
export const SET_IS_AUTH_MENU_OPEN = 'SET_IS_AUTH_MENU_OPEN';
export const SET_OPENED = 'SET_OPENED';
export const SET_MENU_ORIENTATION = 'SET_MENU_ORIENTATION';
export const SET_IS_MOBILE_MENU_OPEN = 'SET_IS_MOBILE_MENU_OPEN';
export const SET_IS_MINI_SWITCH_VISIBILITY = 'SET_IS_MINI_SWITCH_VISIBILITY';

// theme
export const SET_MODE = 'SET_MODE';
export const SET_DIRECTION = 'SET_DIRECTION';
export const SET_FONT_FAMILY = 'SET_FONT_FAMILY';
export const SET_COLOR = 'SET_COLOR';
export const SET_BORDER_RADIUS = 'SET_BORDER_RADIUS';

export const SET_OUTLINED_FILLED = 'SET_OUTLINED_FILLED';
export const SET_BOX_CONTAINER = 'SET_BOX_CONTAINER';

//locale
export const SET_LOCALE = 'SET_LOCALE';

// auth
export const SET_AUTH = 'SET_AUTH';
export const UPDATE_AUTH = 'UPDATA_AUTH';
