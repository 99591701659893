import React from 'react';
import ReactDOM from 'react-dom/client';

//project
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import _config from 'config';
import { HelmetProvider } from 'react-helmet-async';
import AppProviders from './providers';
// import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppProviders appConfig={_config}>
        {/* <BrowserRouter> */}
        <App />
        {/* </BrowserRouter> */}
      </AppProviders>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
