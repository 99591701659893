import React from 'react';

// @mui
import menuItems from 'config/menuItems';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Drawer, Toolbar, styled } from '@mui/material';

// third-party
import { includes } from 'lodash';
import MenuGroup from './MenuGroup';

// project
import { useMenu } from 'providers/Menu';
import { useAppTheme } from 'providers/Theme';
// import { Scrollbar } from 'components/scrollbar';
import { useConfig } from 'providers/Config';



const openedMixin = (theme, appMenuWidth) => ({
  width: appMenuWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  // ...theme.palette.sidemenu
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
  // ...theme.palette.sidemenu
});
const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'siderMenuWidth' })(
  ({ theme, open, siderMenuWidth }) => ({
    width: siderMenuWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, siderMenuWidth),
      '& .MuiDrawer-paper': openedMixin(theme, siderMenuWidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),

);


const SiderMenu = (props) => {
  const { container } = props;
  const { appConfig } = useConfig();
  const { direction } = useAppTheme();
  const { isMiniMode, isMenuOpen, isMobileMenuOpen, toggleMenu } = useMenu();

  const handleMenuToggle = () => {
    if (isMiniMode) {
      toggleMenu('isMenuOpen', false);
    }
  };

  const menus = menuItems?.items?.map((item, index) => {
    if (includes(item.type, 'group')) {
      return (
        <MenuGroup key={index} item={item} />
      );
    }
    return undefined;
  })

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { sm: 0 } }}
      aria-label="sider menu"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        anchor={direction === 'ltr' ? 'left' : 'right'}
        variant="temporary"
        open={isMobileMenuOpen}
        onClose={() => toggleMenu('isMobileMenuOpen', !isMobileMenuOpen)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: appConfig.sizing.siderMenuWidth },
        }}
      >
        <Toolbar />
        {menus}
      </Drawer>

      <StyledDrawer
        anchor={direction === 'ltr' ? 'left' : 'right'}
        variant="permanent"
        open={isMenuOpen}
        siderMenuWidth={appConfig.sizing.siderMenuWidth}
        onMouseOver={() => toggleMenu('isMenuOpen', true)}
        onMouseOut={handleMenuToggle}
        sx={{
          display: { xs: 'none', sm: 'block' }
        }}
      >
        <Toolbar />
        {menus}
      </StyledDrawer>
    </Box>
  );
};

export default SiderMenu;
