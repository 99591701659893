import React, { useState, useEffect } from 'react';
import Context from './Context';
const Provider = ({ children, persistKey = 'theme', appConfig }) => {
  // config theme
  const { theme: themeConfig } = appConfig || {};

  const modeKey = `${persistKey}:mode`;
  const colorKey = `${persistKey}:color`;
  const directionKey = `${persistKey}:direction`;
  const fontFamilyKey = `${persistKey}:fontFamily`;
  const borderRadiusKey = `${persistKey}:borderRadius`;

  // initial theme
  const {
    colors,
    initialMode,
    initialColor,
    initialDirection,
    initialFontFamily,
    initialBorderRadius
    // initialGridSpacing
  } = themeConfig;

  const savedMode = localStorage.getItem(modeKey);
  const savedColor = localStorage.getItem(colorKey);
  const savedDirection = localStorage.getItem(directionKey);
  const savedFontFamily = localStorage.getItem(fontFamilyKey);
  const savedBorderRadius = localStorage.getItem(borderRadiusKey);

  const [mode, setMode] = useState(savedMode || initialMode);
  const [color, setColor] = useState(savedColor || initialColor);
  const [direction, setDirection] = useState(
    savedDirection || initialDirection
  );
  const [fontFamily, setFontFamily] = useState(
    savedFontFamily || initialFontFamily
  );
  const [borderRadius, setBorderRadius] = useState(
    Number(savedBorderRadius) || Number(initialBorderRadius)
  );

  // useEffect(() => {
  //     const savedMode = localStorage.getItem(modeKey);
  //     const savedColor = localStorage.getItem(colorKey);
  //     const savedDirection = localStorage.getItem(directionKey);
  //     const savedFontFamily = localStorage.getItem(fontFamilyKey);
  //     const savedBorderRadius = localStorage.getItem(borderRadiusKey);

  //     if (savedMode) {
  //         console.log("savedMode>>>", savedMode)
  //         setMode(savedMode === 'dark' ? 'dark' : 'light')
  //     }
  //     if (savedColor) {
  //         setColor(savedColor)
  //     }
  //     if (savedDirection) {
  //         setDirection(savedDirection === 'ltr' ? 'ltr' : 'rtl')
  //     }
  //     if (savedFontFamily) {
  //         setFontFamily(savedFontFamily)
  //     }
  //     if (savedBorderRadius) {
  //         setBorderRadius(Number(savedBorderRadius))
  //     }
  // }, [modeKey, colorKey, directionKey, fontFamilyKey, borderRadiusKey])

  useEffect(() => {
    try {
      // console.log("mode>>>", mode)
      if (['dark', 'light'].indexOf(mode) !== -1) {
        localStorage.setItem(modeKey, mode);
      }
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line
  }, [mode, modeKey]);

  useEffect(() => {
    try {
      if (colors.indexOf(color) !== -1) {
        localStorage.setItem(colorKey, color);
      }
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line
  }, [color, colorKey]);

  useEffect(() => {
    try {
      if (['ltr', 'rtl'].indexOf(direction) !== -1) {
        localStorage.setItem(directionKey, direction);
      }
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line
  }, [direction, directionKey]);

  useEffect(() => {
    try {
      localStorage.setItem(fontFamilyKey, fontFamily);
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line
  }, [fontFamily, fontFamilyKey]);

  useEffect(() => {
    try {
      if (Number(borderRadius) > 0) {
        localStorage.setItem(borderRadiusKey, Number(borderRadius));
      }
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line
  }, [borderRadius, borderRadiusKey]);

  return (
    <Context.Provider
      value={{
        colors,
        mode,
        setMode,
        color,
        setColor,
        direction,
        setDirection,
        fontFamily,
        setFontFamily,
        borderRadius,
        setBorderRadius
      }}
    >
      <div
        style={{
          direction
        }}
      >
        {children}
      </div>
    </Context.Provider>
  );
};

export default Provider;
