import { createTheme } from '@mui/material/styles';

// project
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import themeShadows from './shadows';

export const themes = (mode, color, direction, fontFamily, borderRadius) => {
  const themeOptions = {
    direction: direction || 'ltr',
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    // mixins: {
    //     toolbar: {
    //         minHeight: '48px',
    //         padding: '16px',
    //         '@media (min-width: 600px)': {
    //             minHeight: '48px'
    //         }
    //     }
    // },
    palette: themePalette(mode, color),
    shadows: themeShadows,
    shape: {
      borderRadius
    },
    typography: themeTypography(fontFamily)
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themes);
  return themes;
};

export default themes;
