import * as React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

// @mui
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material';

//project
import { useConfig } from 'providers/Config';
import Footer from './Footer';
import AppBar from './AppBar';
import SiderMenu from './SiderMenu';
import GlobalStyles from 'config/themes/globalStyles';

function MainLayout(props) {
  const { window } = props;
  const theme = useTheme();
  const { appConfig } = useConfig();
  const { siderMenuWidth: drawerWidth, footerHeight } = appConfig.sizing;

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{
      boxSizing: 'border-box',
      display: 'flex',
      minHeight: '100vh',
      overflowY: 'scoll'
    }}>
      <CssBaseline />
      <GlobalStyles />
      <AppBar />

      <SiderMenu
        container={container}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: '100vh',
          overflowY: 'scroll',
          pb: `${footerHeight + 5}px`,
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}

      >
        <Toolbar />
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

MainLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainLayout;
