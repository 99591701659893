import { useState, useEffect } from 'react';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Drawer,
  Box,
  Grid,
  IconButton,
  Slider,
  Typography,
  ButtonBase,
  styled
} from '@mui/material';
import { shouldForwardProp } from '@mui/system';
import {
  Circle,
  DarkMode,
  FormatTextdirectionLToR,
  FormatTextdirectionRToL,
  LightMode
} from '@mui/icons-material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconSettings } from '@tabler/icons';

// project
import MainCard from 'components/cards/MainCard';
import { useConfig } from 'providers/Config';
// import { grey, green, lightBlue, blue, lightGreen, purple, red } from '@mui/material/colors';
import { useAppTheme } from 'providers/Theme';

// concat 'px'
function valueText(value) {
  return `${value}px`;
}

// -------------------------------------------------------------------------

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderRadius: `${theme.shape.borderRadius}px`,
    '&:hover': {
      background: theme.palette.primary.dark,
      color: theme.palette.primary.light
    }
  })
);

const Setting = () => {
  const theme = useTheme();
  const { appConfig } = useConfig();
  const {
    setMode,
    setColor,
    direction,
    setDirection,
    fontFamily,
    setFontFamily,
    borderRadius,
    setBorderRadius
  } = useAppTheme();

  // drawer on/off
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  // state - border radius
  const handleBorderRadius = (event, newValue) => {
    setBorderRadius(newValue);
  };
  useEffect(() => {
    setBorderRadius(borderRadius);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borderRadius]);

  // state - font family
  let initialFont;
  switch (appConfig.theme.fontFamily) {
    case `'Inter', sans-serif`:
      initialFont = 'Inter';
      break;
    case `'Poppins', sans-serif`:
      initialFont = 'Poppins';
      break;
    case `'Roboto', sans-serif`:
    default:
      initialFont = 'Roboto';
      break;
  }
  // const [afontFamily, setAFontFamily] = useState(initialFont);
  useEffect(() => {
    let newFont;
    switch (fontFamily) {
      case 'Inter':
        newFont = `'Inter', sans-serif`;
        break;
      case 'Poppins':
        newFont = `'Poppins', sans-serif`;
        break;
      case 'Roboto':
      default:
        newFont = `'Roboto', sans-serif`;
        break;
    }
    setFontFamily(newFont);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFont]);

  // state - theme.pallate.mode
  const handleThemeMode = (mode) => {
    setMode(mode);
  };

  // state - theme color
  const handleThemeColor = (color) => {
    setColor(color);
  };

  // state - theme direction
  const handleThemeDirection = (direction) => {
    setDirection(direction);
  };

  return (
    <>
      {/* toggle button */}
      <Box
        sx={{
          ml: 1,
          mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 0.5
          }
        }}
      >
        <ButtonBase
          sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
          onClick={handleToggle}
        >
          <HeaderAvatarStyle variant="rounded">
            <IconSettings stroke={1.5} size="1.2rem" />
          </HeaderAvatarStyle>
        </ButtonBase>
      </Box>
      <Drawer
        anchor={direction === 'ltr' ? 'right' : 'left'}
        onClose={handleToggle}
        open={open}
        PaperProps={{
          sx: {
            width: 280,
            bgcolor: theme.palette.background.default
          }
        }}
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
      >
        <PerfectScrollbar component="div">
          <Grid
            container
            spacing={appConfig.theme.initialGridSpacing}
            sx={{ p: 2 }}
          >
            <Grid item xs={12}>
              <MainCard
                title={<FormattedMessage id="mode" defaultMessage={`MODE`} />}
                sx={{ border: '0px' }}
              >
                <div style={{ display: 'flex' }}>
                  <IconButton
                    size="large"
                    sx={{ width: 90 }}
                    onClick={() => handleThemeMode('light')}
                  >
                    <LightMode color="error" />
                  </IconButton>
                  <IconButton
                    size="large"
                    sx={{ width: 90, color: theme.palette.text.default }}
                    onClick={() => handleThemeMode('dark')}
                  >
                    <DarkMode />
                  </IconButton>
                  {/* <Avatar sx={{ width: 100, height: 100, bgcolor: appConfig.mode === 'light' ? grey[50] : grey[200] }} variant='square' onClick={() => handleThemeMode('light')}>
                                        <LightMode fontSize='medium' color='warning' />
                                    </Avatar>
                                    <Divider orientation='vertical' />
                                    <Avatar sx={{ width: 100, height: 100, bgcolor: appConfig.mode === 'light' ? grey[200] : grey[50] }} variant='square' onClick={() => handleThemeMode('dark')}>
                                        <DarkMode fontSize='medium' color='texts' />
                                    </Avatar> */}
                </div>
              </MainCard>
            </Grid>
            <Grid item xs={12}>
              <MainCard
                title={<FormattedMessage id="color" defaultMessage={`COLOR`} />}
                sx={{
                  border: '0px',
                  color: theme.palette.text.primary
                }}
              >
                <Box style={{ display: 'flex', flexFlow: 'wrap' }}>
                  {appConfig.theme?.colors?.map((color, index) => (
                    <IconButton
                      key={index}
                      size="medium"
                      sx={{
                        width: 60,
                        color: theme.palette[color][5]
                      }}
                      onClick={() => {
                        handleThemeColor(color);
                      }}
                    >
                      <Circle />
                    </IconButton>
                  ))}
                  {/* <IconButton color='primary' size="medium" sx={{ width: 60 }} onClick={() => { handleThemeColor('lightblue') }}>
                                        <Circle />
                                    </IconButton>
                                    <IconButton color='secondary' size="medium" sx={{ width: 60 }} onClick={() => { handleThemeColor('blue') }}>
                                        <Circle />
                                    </IconButton>
                                    <Divider orientation='vertical' />
                                    <IconButton color='info' size="medium" sx={{ width: 60 }} onClick={() => { handleThemeColor('bluegrey') }}>
                                        <Circle />
                                    </IconButton>
                                    <IconButton color='warning' size="medium" sx={{ width: 60 }} onClick={() => { handleThemeColor('lightgreen') }}>
                                        <Circle />
                                    </IconButton>
                                    <IconButton color='success' size="medium" sx={{ width: 60 }} onClick={() => { handleThemeColor('teal') }}>
                                        <Circle />
                                    </IconButton>
                                    <IconButton color='error' size="medium" sx={{ width: 60 }} onClick={() => { handleThemeColor('purple') }}>
                                        <Circle />
                                    </IconButton> */}
                </Box>
              </MainCard>
            </Grid>
            <Grid item xs={12}>
              <MainCard
                title={
                  <FormattedMessage
                    id="direction"
                    defaultMessage={`DIRECTION`}
                  />
                }
                sx={{
                  border: '0px',
                  color: theme.palette.text.primary
                }}
              >
                <div style={{ display: 'flex' }}>
                  <IconButton
                    color="primary"
                    size="large"
                    sx={{ width: 90 }}
                    onClick={() => handleThemeDirection('ltr')}
                  >
                    <FormatTextdirectionLToR color="primary" />
                  </IconButton>
                  <IconButton
                    color="primary"
                    size="large"
                    sx={{ width: 90 }}
                    onClick={() => handleThemeDirection('rtl')}
                  >
                    <FormatTextdirectionRToL color="primary" />
                  </IconButton>
                </div>
              </MainCard>
            </Grid>
            {/* <Grid item xs={12}>
                            <MainCard title="Orientation">
                                <div style={{ display: 'flex' }}>

                                </div>
                            </MainCard>
                        </Grid> */}
            {/* font family */}
            {/* <Grid item xs={12}>
                            <MainCard title={<FormattedMessage
                                id="fontFamily"
                                defaultMessage={`FONT FAMILY`}
                            />}
                                sx={{
                                    border: '0px',
                                    color: theme.palette.text.primary
                                }}
                            >
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={afontFamily}
                                        onChange={(e) => setAFontFamily(e.target.value)}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="Roboto"
                                            control={<Radio />}
                                            label="Roboto"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.text.primary }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Poppins"
                                            control={<Radio />}
                                            label="Poppins"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.text.primary }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Inter"
                                            control={<Radio />}
                                            label="Inter"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.text.primary }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </MainCard>
                        </Grid>   */}
            <Grid item xs={12}>
              {/* border radius */}
              <MainCard
                title={
                  <FormattedMessage
                    id="border_radius"
                    defaultMessage={`Border Radius`}
                  />
                }
                sx={{
                  border: '0px',
                  color: theme.palette.text.primary
                }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ mt: 2.5 }}
                >
                  <Grid item>
                    <Typography variant="h6" color="secondary">
                      4px
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      size="small"
                      value={borderRadius}
                      onChange={handleBorderRadius}
                      getAriaValueText={valueText}
                      valueLabelDisplay="on"
                      aria-labelledby="discrete-slider-small-steps"
                      marks
                      step={2}
                      min={4}
                      max={24}
                      // color="secondary"
                      sx={{
                        '& .MuiSlider-valueLabel': {
                          color: 'secondary.light'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="secondary">
                      24px
                    </Typography>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Setting;
