import React from 'react';

// @mui
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// third-party
import { includes } from 'lodash';
import MenuItem from './MenuItem';

// project
import { useMenu } from 'providers/Menu';
import { useMediaQuery, useTheme } from '@mui/material';

const MenuCollapse = (props) => {
  const { item } = props;
  const theme = useTheme();

  const { isMenuOpen } = useMenu();
  const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const Icon = item?.icon;
  const itemIcon = item?.icon ? (
    <Icon style={{ width: 24, height: 24 }} />
  ) : undefined;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          minHeight: 48,
          justifyContent: matchDownSm ? 'initial' : isMenuOpen ? 'initial' : 'center',
          px: 3
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: matchDownSm ? 3 : isMenuOpen ? 3 : 'auto',
            justifyContent: 'center'
          }}
        >
          {itemIcon}
        </ListItemIcon>
        <ListItemText
          primary={item.title}
          sx={{ opacity: matchDownSm ? 1 : isMenuOpen ? 1 : 0 }}
        />
        {!matchDownSm && (isMenuOpen ? open ? <ExpandLess /> : <ExpandMore /> : undefined)}
        {matchDownSm ? open ? <ExpandLess /> : <ExpandMore /> : undefined}
      </ListItemButton>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((subItem, index) => (
              <React.Fragment key={index}>
                {includes(subItem.type, 'item') && (
                  <MenuItem item={subItem} isMenuOpen={isMenuOpen} />
                )}
                {includes(subItem.type, 'collapse') && (
                  <MenuCollapse item={subItem} isMenuOpen={isMenuOpen} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default MenuCollapse;
