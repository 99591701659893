import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// from project
import { AuthProvider } from 'providers/Auth';
import { LocaleProvider } from 'providers/Locale';
import { ConfigProvider } from './Config'

const AppProviders = ({ appConfig, children }) => {
    return (
        <ConfigProvider appConfig={appConfig}>
            <AuthProvider>
                <LocaleProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {children}
                    </LocalizationProvider>
                </LocaleProvider>
            </AuthProvider>
        </ConfigProvider>
    )
}

export default AppProviders;